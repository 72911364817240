import {NON_US_PRIVATE, NON_US_PROFESSIONAL, US_INDIVIDUAL, US_INSTITUTIONAL} from "./TabsList";
import moment from "moment";
import {
  fetchAnalyticsData,
  fetchDocumentsNonUsPrivate,
  fetchDocumentsUsIndividual,
  fetchDocumentsUsProfessional,
  fetchIndicesNonUsPrivate,
  fetchIndicesNonUsProfessional,
  fetchIndicesUsIndividual,
  fetchNoticesNonUsPrivate,
  fetchNoticesNonUsProfessional,
  fetchNoticesUsIndividual, fetchNoticesUsInstitutional,
  fetchReportsNonUsPrivate,
  fetchReportsUsIndividual,
  fetchReportsUsProfessional,
  fetchTimeseries
} from "../components/ReactQueries/queries/Queries";
import {Moment} from "moment/moment";

export let reportsParam:string = '';
export let documentsParam:string = '';
export let analyticsParam:string = '';
export let timeseriesParam:string = '';

export const getNavigationSource = selectedProfile => {
  switch(selectedProfile) {
    case "US Individual":
      return US_INDIVIDUAL;
      break;
    case "US Institutional":
      return US_INSTITUTIONAL;
      break;
    case "Non US Private":
      return NON_US_PRIVATE;
      break;
    case "Non US Professional":
      return NON_US_PROFESSIONAL;
      break;
    default:
      return []
  }
}

export const getIndicesQuery = selectedProfile => {
  switch(selectedProfile) {
    case "US Individual":
      return fetchIndicesUsIndividual;
      break;
    case "Non US Private":
      return fetchIndicesNonUsPrivate;
      break;
    case "Non US Professional":
      return fetchIndicesNonUsProfessional;
      break;
  }
}

export const getNoticesQuery = selectedProfile => {
  switch(selectedProfile) {
    case "US Individual":
      return fetchNoticesUsIndividual;
      break;
    case "US Institutional":
      return fetchNoticesUsInstitutional;
      break;
    case "Non US Private":
      return fetchNoticesNonUsPrivate;
      break;
    case "Non US Professional":
      return fetchNoticesNonUsProfessional;
      break;
  }
}

export const getTimeseriesQuery = param => {
  timeseriesParam = param;
  return fetchTimeseries;
}

export const getAnalyticsQuery = param => {
  analyticsParam = param;
  return fetchAnalyticsData;
}


export const getReportsQuery = (selectedProfile, params) => {
  reportsParam = params
  switch(selectedProfile) {
    case "US Individual":
      return fetchReportsUsIndividual;
      break;
    case "Non US Private":
      return fetchReportsNonUsPrivate;
      break;
    case "Non US Professional":
      return fetchReportsUsProfessional;
      break;
  }
}

export const getDocumentsQuery = (selectedProfile, params) => {
  documentsParam = params;
  switch(selectedProfile) {
    case "US Individual":
      return fetchDocumentsUsIndividual;
      break;
    case "Non US Private":
      return fetchDocumentsNonUsPrivate;
      break;
    case "Non US Professional":
      return fetchDocumentsUsProfessional;
      break;
  }
}

export const getTimestampFromFormattedDate = formattedDate => {
  const dateParts = formattedDate?.split('-'); // assuming YYYY-MM-DD
  const d = new Date(Date.UTC(dateParts[0], dateParts[1], dateParts[2], 0, 0, 0));
  return d.getTime();
};

export const getSortedDate = (data) => {
  return data.sort((a, b) => {
    // @ts-ignore
    const dateA = getTimestampFromFormattedDate(moment(Number(a.x)).format('YYYY-MM-DD'));
    // @ts-ignore
    const dateB = getTimestampFromFormattedDate(moment(Number(b.x)).format('YYYY-MM-DD'));
    return dateA - dateB;
  });
}

export const getNumberStyle = (value, isDark=false) => {
  if (value < 0) {
    if (isDark) {
      return 'negative-number-dark'
    }
    return 'negative-number'
  }
  if (isDark) {
    return 'positive-number-dark'
  }
  return 'positive-number'
}

export const getColumnValue = value => {
    return value === null ||  value === "" ? '-' : value
}

export const toSentenceCase = camelCase => {
  if (camelCase) {
    let result = camelCase.replace(/([A-Z])/g, ' $1');
    result = result.trim();
    return result[0].toUpperCase() + result.substring(1).toLowerCase();

  }
  return ''
}

export const getWorkingDay = moment => {
  const workDay = moment();
  const day = workDay.day();
  let diff = 0;
  if (day === 0) {
    diff = day + 2;
  }
  if (day === 6) {
    diff = 1;
  }

  return workDay.subtract(diff, 'days');
};

export const getPreviousYear = (loopBack = 1) => {
  const d = new Date();
  d.setFullYear(d.getFullYear() - loopBack);
  const newDate = new Date(d);
  if (d.getDay() === 6) {
    newDate.setDate(newDate.getDate() - 1);
    return newDate;
  } else if (d.getDay() === 0) {
    newDate.setDate(newDate.getDate() - 2);
    return newDate;
  } else {
    return d;
  }
};
export const makeAvailableDates = (
  firstDate: Moment = moment(getPreviousYear(2)).startOf('day'),
  lastDate: Moment = moment(getWorkingDay(moment)).startOf('day')
): Moment[] => {
  const currDate = firstDate.clone();

  const dates = [currDate.clone()];

  while (currDate.add(1, 'days').diff(lastDate) <= 0) {
    const thisDay = currDate.clone();
    thisDay.day() !== 6 && thisDay.day() !== 0 && dates.push(currDate.clone());
  }
  return dates;
};

export const openInNewTab = url => {
  window.open(url, '_blank', 'noopener, noreferrer');
}

export const getZoomLevel = () => {
  return Math.ceil(( screen.width / window.innerWidth) * 100);
}
