import moment from 'moment';

export const chartOptions = {
  chart: {
    backgroundColor: '#242526',
    type: 'spline',
    zoomType: 'xy',
    spacingRight: 50
  },
  accessibility: {
    enabled:true,
    announceNewData: {
      enabled: false
    },
    keyboardNavigation: {
      enabled: true,
      order: ['series', 'zoom']

    },
    point: {
      dateFormat: '%e %B %Y',
      valueDecimals: 2,
      descriptionFormatter: function(point) {
        return `${moment(point?.x)?.format('DD MMM YY')}, ${point?.y}`
      }
    },
    series: {
      pointDescriptionEnabledThreshold: false
    }
  },
  legend: {
    align: 'left',
    useHTML: true,
    verticalAlign: 'top',
    enabled: false,
    itemDistance: 125,
    itemStyle: {
      'text-align': 'center',
      marginRight: '100px'
    }
  },
  plotOptions: {
    series: {
      turboThreshold: 0,
      dataGrouping: {
        enabled: false
      }
    },
    spline: {

    }
  },
  tooltip: {
    enabled: true,
    backgroundColor: "rgba(36, 37, 38, .8)",
    formatter: function() {
      return `</div>`
    },
    useHTML: true
  },
  title: {
    text: '',
    style: {"display": "none"},
    rotation: 270
  },
  yAxis: {
    gridLineDashStyle: "Solid",
    labels: {
      padding: 0,
      style:{ marginRight: "20px"},
      x: 30
    },
    title: {
      align: 'middle',
      text: 'Index Level',
      rotation: 90,
      style: {color: "#ffffff", fontSize: "13px"},
      x:  40
    },
    opposite: true
  },
  exporting: {
    csv: {
      dateFormat: "%d-%m-%y"
    }
  },
  xAxis: {
    plotBands: [
      {
        color: "rgba(128,172,215,0.1)",
        from: null,
        to: null
      },
      {
        color: "#242526",
        from: null,
        to: null,
        label: {
          text: 'Live',
          align: 'left',
          style: {'color': '#ffffff'}
        }
      }
    ],
    plotLines: [
      {
        color: "#ffffff",
        value: null,
        width: 3
      }
    ],
    backgroundColor: "rgba(128,172,215,0.1)",
    gridLineDashStyle: "Solid",
    gridLineWidth: 1,
    description: '',
    maxPadding: 0,
    labels: {
      formatter: (val) => {
        return moment(val?.value).format('DD MMM YY');
      }
    }
  },
  rangeSelector: {
    labelStyle: {
      display: 'none'
    },
    buttons:[

    ],
    enabled:true,
    inputPosition: {
      align:'left',
      x:150,
      y:-30
    },
    inputEnabled: false
  },
  series: [

  ]
};
export const createCustomTooltip = (dateFormat = 'DD MMM YY') => {
  chartOptions.tooltip.formatter = function() {
    // @ts-ignore
    const points = this;
    // @ts-ignore
    return `<div class="tooltip-header tooltip-header-name">
                <div>${// @ts-ignore
                        moment(new Date(points?.x))?.format('dddd')
                      },
                      ${// @ts-ignore
                        moment(points?.x)?.format(dateFormat)
    }</div>
                                <div>${// @ts-ignore
                              points?.y?.toFixed(2)
                            }</div>
            </div>`
  }
}

export const setXAxisDateFormat = (dateFormat = 'DD MMM YY') => {
  chartOptions.xAxis.labels.formatter = (val) => {
    return moment(val?.value).format(dateFormat);
  }
}

export const setXAxisPlotBand = (fromDate, toDate, startDate) => {
  chartOptions.xAxis.plotBands[0].from = startDate;
  chartOptions.xAxis.plotBands[0].to = fromDate;
  chartOptions.xAxis.plotBands[0].to = fromDate;
  chartOptions.xAxis.plotBands[1].from = fromDate;
  chartOptions.xAxis.plotBands[1].to = toDate;
  chartOptions.xAxis.plotLines[0].value = fromDate;
}
